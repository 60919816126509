"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var settingsStore_1 = require("@/state/settingsStore");

var defaultSlot = {
  start: 0,
  ende: 0,
  dauer: 0,
  percentage: 0
};
exports["default"] = (0, vue_1.defineComponent)({
  props: {
    termin: {
      type: Object,
      required: true
    }
  },
  computed: {
    wzs: function wzs() {
      var _a;

      return Object.assign(Object.assign({}, defaultSlot), (_a = this.termin.zeitslots) === null || _a === void 0 ? void 0 : _a.warteZimmerSlot);
    },
    bzs: function bzs() {
      var _a;

      return Object.assign(Object.assign({}, defaultSlot), (_a = this.termin.zeitslots) === null || _a === void 0 ? void 0 : _a.behandlungsZimmerSlot);
    },
    bs: function bs() {
      var _a;

      return Object.assign(Object.assign({}, defaultSlot), (_a = this.termin.zeitslots) === null || _a === void 0 ? void 0 : _a.behandlungsSlot);
    },
    hasTerminzeit: function hasTerminzeit() {
      return this.termin.wartezimmerzeit || this.termin.behandlungszimmerzeit || this.termin.behandlungsstartzeit;
    },
    hasBehandlungszimmerzeit: function hasBehandlungszimmerzeit() {
      return !settingsStore_1.settingsStore.getters.isZ1;
    },
    settingsWartezeiten: function settingsWartezeiten() {
      return settingsStore_1.settingsStore.state.settings.wartezeiten;
    }
  }
});