/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ZimmerbelegungTerminList.vue?vue&type=template&id=cf60672c&scoped=true&lang=pug&"
import script from "./ZimmerbelegungTerminList.vue?vue&type=script&lang=ts&"
export * from "./ZimmerbelegungTerminList.vue?vue&type=script&lang=ts&"
import style0 from "./ZimmerbelegungTerminList.vue?vue&type=style&index=0&id=cf60672c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf60672c",
  null
  
)

export default component.exports